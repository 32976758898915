import React from "react";
import "../styles/AGB.css"; // Stelle sicher, dass die CSS-Datei vorhanden ist
import Navbar from "./Navbar";

const AGB = () => {
    return (
        <>
            <Navbar />
            <div className='AGB-container'>
                <h1 className='AGB-title'>
                    Allgemeine Geschäftsbedingungen (AGB)
                </h1>

                <h2 className='AGB-section-title'>1. Geltungsbereich</h2>
                <p className='AGB-paragraph'>
                    Diese Allgemeinen Geschäftsbedingungen gelten für alle
                    Verträge, die zwischen Giovannis Apartments (im Folgenden
                    „wir“ oder „uns“) und den Nutzern (im Folgenden „Kunde“ oder
                    „Sie“) über unsere Website www.giovannisapartments.de zur
                    Buchung von Apartments abgeschlossen werden.
                </p>

                <h2 className='AGB-section-title'>2. Vertragspartner</h2>
                <p className='AGB-paragraph'>
                    Der Vertrag kommt zustande mit:
                    <br />
                    Giovannis Apartments
                    <br />
                    Bürgermeister-Wohlfarth-Straße 89, 86343 Königsbrunn
                    <br />
                    giovannisapartments@gmail.com
                    <br />
                    0151 68696510
                </p>

                <h2 className='AGB-section-title'>3. Vertragsgegenstand</h2>
                <p className='AGB-paragraph'>
                    Wir bieten Ihnen die Möglichkeit, Apartments über unsere
                    Website zu buchen. Die Buchung erfolgt durch Auswahl des
                    gewünschten Apartments und Bestätigung Ihrer Buchung durch
                    Klicken auf den Button „Jetzt buchen“.
                </p>

                <h2 className='AGB-section-title'>
                    4. Preise und Zahlungsbedingungen
                </h2>
                <p className='AGB-paragraph'>
                    Die angegebenen Preise sind Endpreise und enthalten die
                    gesetzliche Mehrwertsteuer sowie mögliche zusätzliche Kosten
                    (z.B. Reinigung). Die Zahlung erfolgt über PayPal. Eine
                    Buchung ist erst dann verbindlich, wenn der volle Betrag
                    bezahlt wurde.
                </p>

                <h2 className='AGB-section-title'>
                    5. Stornierung und Rücktritt
                </h2>
                <p className='AGB-paragraph'>
                    Der Kunde hat das Recht, die Buchung bis zu 1 Tag vor
                    Anreise kostenfrei zu stornieren. Bei späterer Stornierung
                    sind 30% des Buchungsbetrags fällig. Stornierungen sind
                    schriftlich an{" "}
                    <a href='mailto:giovannisapartments@gmail.com?subject=Buchungsstornierung'>
                        <b>giovannisapartments@gmail.com</b>
                    </a>{" "}
                    zu richten.
                </p>

                <h2 className='AGB-section-title'>6. Haftung</h2>
                <p className='AGB-paragraph'>
                    Wir haften für Schäden, die durch grobe Fahrlässigkeit oder
                    Vorsatz verursacht wurden. Für einfache Fahrlässigkeit
                    haften wir nur bei Verletzung wesentlicher
                    Vertragspflichten. In diesem Fall ist unsere Haftung auf den
                    typischerweise vorhersehbaren Schaden beschränkt.
                </p>

                <h2 className='AGB-section-title'>7. Datenschutz</h2>
                <p className='AGB-paragraph'>
                    Informationen zur Erhebung, Verarbeitung und Nutzung
                    personenbezogener Daten finden Sie in unserer{" "}
                    <a
                        style={{ textDecoration: "underline" }}
                        href='/datenschutz'
                    >
                        Datenschutzerklärung
                    </a>
                    .
                </p>

                <h2 className='AGB-section-title'>8. Schlussbestimmungen</h2>
                <p className='AGB-paragraph'>
                    Auf Verträge zwischen uns und den Kunden findet das Recht
                    der Bundesrepublik Deutschland Anwendung. Sollten einzelne
                    Bestimmungen dieser AGB unwirksam sein, bleibt die
                    Wirksamkeit der übrigen Bestimmungen unberührt.
                </p>

                <p className='AGB-paragraph'>Stand: 11.10.2024</p>
            </div>
        </>
    );
};

export default AGB;
