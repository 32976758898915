import Navbar from "./Navbar";
import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa"; // Importiere die gewünschten Icons
import "../styles/ContactPage.css"; // Add custom styling for layout and design

function ContactPage() {
    return (
        <>
            <Navbar />
            <div className='CP-container'>
                <h2 className='CP-heading'>Kontaktieren Sie uns</h2>

                <p className='CP-subheading'>
                    Telefonisch erreichen Sie uns täglich von{" "}
                    <b>10:00 - 18:00 Uhr</b>.
                </p>

                <p className='CP-subheading'>
                    Oder schicken Sie uns einfach eine E-Mail unter{" "}
                    <a
                        href='mailto:giovannisapartments@gmail.com'
                        style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                        }}
                    >
                        giovannisapartments@gmail.com
                    </a>
                </p>
            </div>
            <p
                style={{
                    border: "1px solid black",
                    padding: ".5rem",
                    fontWeight: "bold",
                    width: "40%",
                    margin: "1.5rem auto",
                    textAlign: "center",
                }}
            >
                Wir melden uns schnellstmöglich bei Ihnen zurück.
            </p>
            <div className='CP-info'>
                <p>
                    <FaPhone className='CP-icon rotate-icon' /> Telefon: <br />
                    <a href='tel:015168696510' className='CP-call-link'>
                        <span>0151 68696510</span>
                    </a>
                </p>
                <p>
                    <FaEnvelope className='CP-icon' /> Email: <br />
                    <a
                        href='mailto:giovannisapartments@gmail.com'
                        className='CP-email-link'
                    >
                        <span>giovannisapartments@gmail.com</span>
                    </a>
                </p>
                <p>
                    <FaMapMarkerAlt className='CP-icon' /> Adresse: <br />
                    <a
                        href='https://www.google.com/maps?q=Bürgermeister-Wohlfarth-Straße+89,+86343+Königsbrunn'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='CP-map-link'
                    >
                        <span>
                            Bürgermeister-Wohlfarth-Straße 89, 86343 Königsbrunn
                        </span>
                    </a>
                </p>
            </div>
        </>
    );
}

export default ContactPage;
