import React from "react";
import { useLocation } from "react-router-dom";
import "../styles/OrderConfirmationPage.css";

const OrderConfirmation = () => {
    const location = useLocation();

    // Destructure state from location
    const {
        orderNumber,
        apartmentTitle,
        totalPrice,
        nights,
        startDate,
        endDate, // Ensure this is present
        buyerInfo,
    } = location.state || {}; // Handle undefined state

    // Function to format the date to German format (DD.MM.YYYY)
    const formatDate = (dateString) => {
        if (!dateString || isNaN(new Date(dateString).getTime())) {
            return "Datum nicht verfügbar"; // Fallback for invalid date
        }
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        return new Intl.DateTimeFormat("de-DE", options).format(
            new Date(dateString)
        );
    };

    return (
        <>
            <div className='OC-container'>
                <h2 className='OC-title'>Vielen Dank für die Reservierung!</h2>
                <h3 className='OC-title-h3'>
                    Das Apartment steht für Sie bereit.
                </h3>
                <p style={{ marginBlockEnd: "1rem" }}>
                    {" "}
                    <span style={{ color: "red", fontWeight: "bold" }}>
                        Hinweis:
                    </span>{" "}
                    Unsere Apartments befinden sich <b>alle</b> in der{" "}
                    <a
                        href='https://www.google.com/maps/search/?api=1&query=Bürgermeister-Wohlfarth-Straße+89,+86343+Königsbrunn'
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ textDecoration: "underline" }}
                    >
                        Bürgermeister-Wohlfarth-Straße 89
                    </a>
                </p>
                <div className='OC-details'>
                    <p className='OC-item'>
                        <strong>Bestellnummer:</strong> {orderNumber}
                    </p>
                    <p className='OC-item'>
                        <strong>Apartment:</strong> {apartmentTitle}
                    </p>
                    <p className='OC-item'>
                        <strong>Gesamtpreis:</strong> €{totalPrice}
                    </p>
                    <p className='OC-item'>
                        <strong>Anzahl der Nächte:</strong> {nights}
                    </p>
                    <p className='OC-item'>
                        <strong>Check-in:</strong> {formatDate(startDate)}
                    </p>
                    <p className='OC-item'>
                        <strong>Check-out:</strong> {formatDate(endDate)}
                    </p>
                    <p className='OC-item'>
                        <strong>Name:</strong>{" "}
                        {buyerInfo?.name || "Nicht verfügbar"}
                    </p>
                    <p className='OC-item'>
                        <strong>E-Mail:</strong>{" "}
                        {buyerInfo?.email || "Nicht verfügbar"}
                    </p>
                    <p className='OC-item'>
                        <strong>Telefonnummer:</strong>{" "}
                        {buyerInfo?.phone || "Nicht angegeben"}
                    </p>
                </div>
            </div>
        </>
    );
};

export default OrderConfirmation;
